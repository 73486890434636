<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col class="text-right">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
              :disabled="canClickExportButton"
            >
              <template #button-content>
                <b-button
                  variant="outline-primary"
                  class="add-custom-btn mr-50 mb-1"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                    class="mr-50"
                  />
                  Export <span v-if="groupsSelected && groupsSelected.length">({{ groupsSelected.length }})</span>
                </b-button>
              </template>

              <b-dropdown-item @click="onDownloadGroup('csv')">
                <span class="align-middle ml-50">Export CSV</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDownloadGroup('xlsx')">
                <span class="align-middle ml-50">Export XLSX</span>
              </b-dropdown-item>
            </b-dropdown>
            <action-button
              class="add-custom-btn mr-50 mb-1"
              variant="primary"
              @click="openAdd"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Add Custom Group</span>
            </action-button>
            <action-button
              class="add-custom-btn mb-1"
              variant="primary"
              :to="{ name: 'champion-advanced-search', params: { id: defaultProgramId } }"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Add Filtered Group</span>
            </action-button>
          </b-col>
        </b-row>
        <groups-list
          ref="groupsList"
          @groupsSelected="onChangeGroupsSelected"
        />
        <create-custom-group
          :open="openAddCreateGroup"
          @close="closeSideBar"
          @create="updateList"
        />
      </b-card>
    </b-col>
  </b-row>
</template>
  
<script>
import { BRow, BCol, BButton, BCard, BDropdown, BDropdownItem } from "bootstrap-vue";
import GroupsList from "./GroupsList.vue";
import { mapGetters } from 'vuex';
import CreateCustomGroup from "./CreateCustomGroup.vue";
import { BE_API_URL } from "@/constants/app";
import ActionButton from "@/views/components/common/ActionButton.vue";

export default {
  name: 'Groups',
  components: {
    ActionButton,
    BRow,
    BCol,
    BButton,
    BCard,
    BButton,
    GroupsList,
    CreateCustomGroup,
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      openAddCreateGroup: false,
      groupsSelected: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId']),
    canClickExportButton() {
      return !(this.groupsSelected && this.groupsSelected.length);
    }
  },
  methods: {
    openAdd () {
      this.openAddCreateGroup = true;
    },
    closeSideBar () {
      this.openAddCreateGroup = false;
    },
    updateList() {
      this.$refs.groupsList.loadItems();
    },
    async onDownloadGroup(type) {
      let groupsSelectedEncode = this.groupsSelected.map(group =>
        encodeURIComponent(JSON.stringify({ id: group.id }))
      );
      let dowloadLink = `${BE_API_URL}/programs/${this.$route.params.id}/groups/download/${type}` + `?groups=[${groupsSelectedEncode.join(",")}]`;
      window.document.location = dowloadLink;
    },
    onChangeGroupsSelected(groups) {
      this.groupsSelected = groups;
    }
  },
};
</script>
